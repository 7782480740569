const use404PageVIewTracking = router => {
  try {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'page_view',
        page: {
          page_title: '404 page',
          page_location: `${process.env.NEXT_PUBLIC_SITEURL}${router.asPath}`,
          page_path: router.asPath,
        },
      });
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

          window.dataLayer.push({
            event: 'page_view',
            page: {
              page_title: '404 page',
              Page_location: `${process.env.NEXT_PUBLIC_SITEURL}${router.asPath}`,
              page_path: router.asPath,
            },
          });
        }
      }, 500);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was en error sending data to Google Tag Manager', error);
  }
};

export default use404PageVIewTracking;
