import React, { useEffect } from 'react';
import Layout from 'components/layout/layout';
import { getGlobal } from 'lib/contentful';
import use404PageViewTracking from 'hooks/tracking/use404PageViewTracking';
import { useSettings } from 'contexts';
import { useRouter } from 'next/router';
import PageFourOhFour from 'components/pages/404/pageFourOhFour';
import Head from 'next/head';
import { reformatLocaleToContentfulLocale } from '../lib/utils/helpers';
import { useSalons } from '../hooks';

export default function FourOhFour({ globalModules, locale }) {
  const { salons } = useSettings();
  const router = useRouter();

  useSalons(locale);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    use404PageViewTracking(router);
  }, [router, salons, locale]);

  return (
    <Layout locale={locale} globalModules={globalModules} is404>
      <Head>
        <title>{globalModules?.page404?.seoTitle?.text}</title>
      </Head>
      <PageFourOhFour locale={locale} globalModules={globalModules} />
    </Layout>
  );
}

export async function getStaticProps({ locale }) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);
  const globalModules = await getGlobal(
    ['announcement-bar', 'firstcountryselectorlocales', 'cookies', 'page404', 'productpage'],
    reformattedLocale
  );

  return {
    props: { globalModules, locale },
  };
}
