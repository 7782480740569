import Button from 'components/shared/button';
import Image from 'components/shared/image/image';
import SiteLink from 'components/shared/sitelink/sitelink';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useIsMobile from 'hooks/utils/useIsMobile';
import classNames from 'classnames';
import styles from './pageFourOhFour.module.scss';

const PageFourOhFour = ({ locale, globalModules }) => {
  const { text, banner, button, searchLink, icons, usp } = globalModules?.page404 || {};
  const isMobile = useIsMobile();
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slidesPerView: 1,
      autoplay: true,
      disabled: !isMobile,
    },
    [
      slider => {
        // todo try catch
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    <>
      <div className={classNames(styles.headerUSP, { 'keen-slider': isMobile })} ref={sliderRef}>
        {usp?.texts.map((t, index) => (
          <div
            key={`usp404_${index}`}
            className={classNames(styles.slide, { 'keen-slider__slide': isMobile })}
          >
            <span className={styles.uspIcon}>
              <svg
                width="1.3rem"
                height="0.9rem"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.33398 5L5.25065 7.91667L11.6673 1.5"
                  stroke="#3C3C3B"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            </span>
            <h3 className={styles.slideText}>{t}</h3>
          </div>
        ))}
      </div>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <h3 className={styles.pageNotFoundText}>{text?.text}</h3>
          <SiteLink
            locale={locale}
            href={addLocaleToHref('search', locale)}
            className={styles.searchLink}
          >
            {searchLink?.text}
          </SiteLink>
          <Button locale={locale} className={styles.backToHomeButton} button={button?.button} />
          <div className={styles.iconContainer}>
            {icons?.media?.map((icon, index) => (
              <Image
                key={`icon404_${index}`}
                src={icon?.image}
                className={styles.icon}
                layout="responsive"
                loading="lazy"
              />
            ))}
          </div>
        </div>
        <Image
          src={banner?.media?.[0]?.image}
          className={styles.banner}
          layout="responsive"
          loading="lazy"
        />
      </div>
    </>
  );
};

export default PageFourOhFour;
